import i18n from '@/plugins/i18n';
import allCountries from '@/assets/all-countries';
// valida Cpf
const validateCPF = (strCPF) => {
	strCPF = strCPF.replace(/[^\d]+/g, '');

	if (strCPF == '') return false;
	if (strCPF.length != 11) return false;
	let Soma = 0;
	// Elimina strCPF invalidos conhecidos
	if (
		strCPF == '00000000000' ||
		strCPF == '11111111111' ||
		strCPF == '22222222222' ||
		strCPF == '33333333333' ||
		strCPF == '44444444444' ||
		strCPF == '55555555555' ||
		strCPF == '66666666666' ||
		strCPF == '77777777777' ||
		strCPF == '88888888888' ||
		strCPF == '99999999999'
	) {
		return false;
	}
	for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
	let Resto = (Soma * 10) % 11;
	if (Resto == 10 || Resto == 11) Resto = 0;
	if (Resto != parseInt(strCPF.substring(9, 10))) return false;
	Soma = 0;
	for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
	Resto = (Soma * 10) % 11;
	if (Resto == 10 || Resto == 11) Resto = 0;
	return Resto == parseInt(strCPF.substring(10, 11));
};
// valida Cnpj
const validateCnpj = (cnpj) => {
	cnpj = cnpj.replace(/[^\d]+/g, '');
	if (cnpj == '') return false;
	if (cnpj.length != 14) return false;
	// Elimina CNPJs invalidos conhecidos
	let invalidSamples = [
		'00000000000000', 
		'11111111111111', 
		'22222222222222', 
		'33333333333333', 
		'44444444444444', 
		'55555555555555', 
		'66666666666666', 
		'77777777777777', 
		'88888888888888', 
		'99999999999999']
	if (cnpj in invalidSamples) {
		return false;
	}
	// Valida DVs
	let tamanho = cnpj.length - 2;
	let numeros = cnpj.substring(0, tamanho);
	let digitos = cnpj.substring(tamanho);
	let soma = 0;
	let i;
	let pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(0)) return false;
	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	return resultado == digitos.charAt(1);
};
//valida DDI de telefone/celular estrangeiro - recebe números sem formato definido, com ao menos um espaço separando DDI do resto do número
const validateDDI = (val) => {
	let validChars = /[+()-]/g; //detecta os caracteres válidos '+', '(', ')' e '-'
	val = val.toString().replaceAll(validChars, '').trim(); //remove caracteres válidos não numéricos

	let expression = /[^0-9\s]/g; //detecta todos os caracteres exceto números e espaços
	val = val.toString().replaceAll(expression, ''); //remove caracteres inválidos

	let ddi = val.split(' ')[0]; //separa o ddi do resto do número
	let hasDdi = allCountries.some(f => f.dialCode == ddi); //procura na lista de países pelo ddi
	return hasDdi; //retorna se encontrou ddi
};
//valida celular nacional - recebe números no formato "+55 (##) #####-####"
const validateCellPhoneBR = (value) => {
	value = getPhoneValueWithoutDDI(value);

	let expression = new RegExp('^[1-9]{2}9[1-9]{1}[0-9]{7}$');
	let regex = new RegExp(expression);
	return regex.test(value);
};
//valida telefone fixo nacional - recebe numeros no formato "+55 (##) ####-####"
const validatePhoneBR = (value) => {
	value = getPhoneValueWithoutDDI(value);

	let expression = new RegExp('^[1-9]{2}[2-8]{1}[0-9]{7}$');
	let regex = new RegExp(expression);
	return regex.test(value);
};
//extrai DDI e caracteres especiais
function getPhoneValueWithoutDDI(value) {
	value = value.replaceAll("(", '');
	value = value.replaceAll(")", '');
	value = value.replaceAll("-", '');
	value = value.replaceAll("+", '');
	value = value.replaceAll(" ", '');
	value = value.slice(2, 13);
	return value;
}
// valida cep
const validateCEP = (value) => {
	value = value.replaceAll('.', '');
	value = value.replaceAll('-', '');
	var expression = new RegExp('^[0-9]{8}$');
	let regex = new RegExp(expression);
	return regex.test(value);
};
// valida número min de caracteres para ser uma inscrição estadual
const validateIE = (value) => {
	return value.length >= 8;
};
// valida número min de caracteres para ser um RG
const validateRG = (value) => {
	return value.length >= 4;
};
// valida email
const validateEmail = (email) => {
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};
// valida número negativos, positivos, decimais com ponto e vírgula
const validateNumbers = (num) => {
	var numbers = /^-?\d*\.|,?\d+$/;
	return numbers.test(num);
};
// valida números inteiros
const validateIntegerNumbers = (num) => {
	if (isNaN(num)) return false;
	let numbers = /^[0-9]+$/;
	return numbers.test(num);
};
// valida porcentagem
const validatePorcentagem = (value, verificarNegativo = false) => {
	return !verificarNegativo ? +value >= 0 && +value <= 100 : +value >= -100 && +value <= 100;
};
// valida latitude
const validateLatitude = (lat) => {
	let regex = /[a-zA-Z]/g;
	let invalid = regex.test(lat);
	return invalid ? !invalid : lat >= -90 && lat <= 90 && lat != 0.0;
};
// valida longitude
const validateLongitude = (lng) => {
	let regex = /[a-zA-Z]/g;
	let invalid = regex.test(lng);
	return invalid ? !invalid : lng >= -180 && lng <= 180 && lng != 0.0;
};
// valida url
const validateURL = (url) => {
	let expression = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gi;
	let regex = new RegExp(expression);
	return regex.test(String(url));
};
// se valor passado estiver na lista retorna como valor inválido
const isNull = (val) => {
	let tests = [null, '', ' ', undefined, 'null', NaN];
	return tests.includes(val);
};
const isZero = (val) => {
	let tests = [0, '0'];
	return tests.includes(val);
};
// remove caracteres especiais de uma string
const removeSpecial = (val) => {
	if (typeof val == 'string') {
		val = val.toLowerCase().trim();
		let a = /[âãàáä]/g;
		let e = /[êèéë]/g;
		let i = /[îìíï]/g;
		let o = /[ôõòóö]/g;
		let u = /[ûùúü]/g;
		let y = /[ýÿ]/g;
		let n = /ñ/g;
		let c = /ç/g;
		let special = /[^a-z0-9]/gi;
		return val
			.replace(a, 'a')
			.replace(e, 'e')
			.replace(i, 'i')
			.replace(o, 'o')
			.replace(u, 'u')
			.replace(y, 'y')
			.replace(n, 'n')
			.replace(c, 'c')
			//.replace(special, '-');
	} else {
		console.log('[UTIL] Dado passado não é uma string');
		return val;
	}
};

const removeSpecialUpper  = (val) => {
	if (typeof val == 'string') {
		val = val.trim();
		let a = /[âãàáä]/g;
		let e = /[êèéë]/g;
		let i = /[îìíï]/g;
		let o = /[ôõòóö]/g;
		let u = /[ûùúü]/g;
		let y = /[ýÿ]/g;
		let n = /ñ/g;
		let c = /ç/g;
		let A = /[ÂÃÀÁÄ]/g;
		let E = /[ÊÈÉË]/g;
		let I = /[ÎÌÍÏ]/g;
		let O = /[ÔÕÒÓÖ]/g;
		let U = /[ÛÚÙÜ]/g;
		let Y = /[Ý]/g;
		let N = /Ñ/g;
		let C = /Ç/g;
		
		return val
			.replace(a, 'a')
			.replace(e, 'e')
			.replace(i, 'i')
			.replace(o, 'o')
			.replace(u, 'u')
			.replace(y, 'y')
			.replace(n, 'n')
			.replace(c, 'c')
			.replace(A, 'A')
			.replace(E, 'E')
			.replace(I, 'I')
			.replace(O, 'O')
			.replace(U, 'U')
			.replace(Y, 'Y')
			.replace(N, 'N')
			.replace(C, 'C');
	} else {
		console.log('[UTIL] Dado passado não é uma string');
		return val;
	}
};

// remove caracteres que não devem ir ao db
const removeToSend = (value) => {
	if (typeof value == 'string') {
		let expression = /[^a-zA-Z0-9 âãàáäêèéëîìíïôõòóöûùúüýÿñç/ \-?!.@\n]/gi;
		return value.replace(expression, ' ').trim();
	} else {
		console.log('[removeToSend] Dado passado não é uma string.');
		return value;
	}
};
// remove qualquer caractere que não seja numérico
const justNumbers = (value) => {
	let type = typeof value;
	if (type == 'string' || type == 'number') {
		let expression = /[^0-9\n]/gi;
		return value.toString().replace(expression, '').trim();
	} else {
		console.log('[justNumbers] Dado passado não é uma string e nem número');
		return value;
	}
};
// para arrays de números inteiros convertidos em string
const justNumbersAndComma = (value) => {
	let type = typeof value;
	if (type == 'string' || type == 'number') {
		let expression = /[^0-9 ,\n]/gi;
		return value.toString().replace(expression, '').trim();
	} else {
		console.log('[justNumbersAndComma] Dado passado não é uma string.');
		return value;
	}
};
// para números com casa decimal
const justNumbersAndDot = (value) => {
	let type = typeof value;
	if (type == 'string' || type == 'number') {
		let expression = /[^0-9 .\n]/gi;
		return value.toString().replace(expression, '').trim();
	} else {
		console.log('[justNumbersAndComma] Dado passado não é uma string.');
		return value;
	}
};
// validação de url amigável
const validateFriendlyurl = (friendlyurl) => {
	let expression = /^[a-zA-Z0-9-]*$/;
	let regex = new RegExp(expression);
	return regex.test(friendlyurl);
};
//gerador de UUID
function uuid() {
	// return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
	// 	(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	// );
	let dt = new Date().getTime();
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		let r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
}

// caso não tenha tradução retorna o valor de msg, caso contrário retorna a tradução
function getTranslation(toTranslate = null, msg = 'sovis-store') {
	if (isNull(toTranslate)) {
		return i18n.te(msg) ? i18n.t(msg) : msg;
	} else {
		return i18n.te(toTranslate) ? i18n.t(toTranslate) : toTranslate;
	}
}
// Ignora caixa alta e baixa para comparação
const compareIgnoreCase = (val1, val2) => {
	val1 = isNull(val1)? '' : val1;
	val2 = isNull(val2)? '' : val2;
	return val1.toLowerCase() == val2.toLowerCase();
};

//troca uma string de data de DD/MM/YYYY para YYYY-MM-DD -> método da biblioteca "moment" está depreciado
const reverseDate = (date) => {
	const parts = date.split("/"); 
	return (parts[2] + "-" + parts[1] + "-" + parts[0]);
};

//arredonda números conforme número de casas decimais informado, arredondando para cima em caso de final 5
const roundCurrency = (num) => {
	return ((Math.round(num * 100))/100).toFixed(2);
}

//faz cópia profunda de um objeto sem referência na memória
const deepCopyObject = (object) => {
	return Object.assign({}, JSON.parse(JSON.stringify(object)));
}

//faz cópia profunda de um array sem referência na memória
const deepCopyArray = (array) => {
	return Object.assign([], JSON.parse(JSON.stringify(array)));
}

// função para preparar objetos para serem salvos em banco, conforme padrões do Web/FV
const mapTosave = (value, ...exs) => {
	// exs = campos excluidos da verificação
	// Ex: Util.mapTosave(Objformulario, 'uuid', 'data')
	const A = /[ÂÃÀÁÄ]/g;
	const E = /[ÊÈÉË]/g;
	const I = /[ÎÌÍÏ]/g;
	const O = /[ÔÕÒÓÖ]/g;
	const U = /[ÛÙÚÜ]/g;
	const Y = /[ÝŸ]/g;
	const N = /Ñ/g;
	const C = /Ç/g;

	const special = /[^a-zA-Z0-9 \-?!.,@\n]/gi;

	if (typeof value == "string") {
		return value.toUpperCase()
			.replace(A, 'A')
			.replace(E, 'E')
			.replace(I, 'I')
			.replace(O, 'O')
			.replace(U, 'U')
			.replace(Y, 'Y')
			.replace(N, 'N')
			.replace(C, 'C')
			.replace(special, ' ')
			.trim();
	} else {
		for (const key in value) {
			if (
				exs.indexOf(key) == -1
				&& !key.includes('datahora')
				&& key != 'datainicio'
				&& key != 'datafim'
				&& key != 'data'
				&& key != 'color'
				&& key != 'cor'
				&& key != 'icon'
				&& key != 'statusinsercao'
				&& key != 'hash'
				&& key != 'login'
				&& key != 'senha'
				&& !key.includes('uuid')
			) {
				const el = value[key];
				if (typeof el == 'string') {
					value[key] = el
					.toUpperCase()
					.replace(A, 'A')
					.replace(E, 'E')
					.replace(I, 'I')
					.replace(O, 'O')
					.replace(U, 'U')
					.replace(Y, 'Y')
					.replace(N, 'N')
					.replace(C, 'C')
					.replace(special, ' ')
					.trim();
				}
			}
		}
	}
	return value;
};

// classe para ser usada no throw
// Ex: throw new this.ErrorMsg('without-data');
class ErrorMsg {
	constructor(msg) {
		this.response = {
			data: {
				message: msg,
				type: 'ErrorMsg',
			},
		};
	}
}

export default {
	validateCPF,
	validateCnpj,
	validateCellPhoneBR,
	validatePhoneBR,
	validateDDI,
	validateCEP,
	validateIE,
	validateRG,
	validateEmail,
	validatePorcentagem,
	validateLatitude,
	validateLongitude,
	validateURL,
	validateNumbers,
	validateIntegerNumbers,
	isNull,
	isZero,
	removeSpecial,
	removeToSend,
	justNumbers,
	justNumbersAndComma,
	justNumbersAndDot,
	validateFriendlyurl,
	uuid,
	getTranslation,
	ErrorMsg,
	compareIgnoreCase,
	removeSpecialUpper,
	reverseDate,
	mapTosave,
	roundCurrency,
	deepCopyObject,
	deepCopyArray
};
